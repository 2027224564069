import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { buisness } from '../Models/buisness';

@Injectable({
  providedIn: 'root'
})
export class BuisnessService {

  constructor(private http: HttpClient) { }
  url_buisness=`https://api.complete-energy.co/api/Businesses`;

  ngOnInit() {          
  }
  addBuisnessSctions(buisnesses : buisness)
  {
    return this.http.post(this.url_buisness,buisnesses);
  } 
  getBuisnessSections(){
    return this.http.get(this.url_buisness);
  }
  deleteBuisnessSections(id:any): Observable<any> {
    return this.http.delete(`${this.url_buisness}/${id}`);
  }
  updateBuisnessSections(id:any,service:any): Observable<any> {
    return this.http.put(`${this.url_buisness}/${id}`,service);
  }
  getBuisnessSectionByID(id:any): Observable<any> {
    return this.http.get(`${this.url_buisness}/${id}`);
  }
  
}
