<div class="container-xxl">
    <!-- <app-header></app-header> -->
<router-outlet></router-outlet>


    <app-footer *ngIf="langset == 'false';"></app-footer>
    <app-footer *ngIf="langset == null;"></app-footer>

    <app-footer-ar *ngIf="langset == 'true';"></app-footer-ar>
</div>

