 <!-- Footer Section -->
 <footer>
  <div class="container">
    <div class="foot row" style="padding-top: 100px; color: #fff; text-align: initial;">
      <div class="logo col-sm-2 mb-5">
        <a href="index.html"><img class="img-fluid" src="../../../assets/images/Logo/Lockup_white.png?v=1.3"></a>
      </div>

      <div class="col-sm-2 mb-5">
        <ul class="list-unstyled" >
          Quick Links
          <hr />
          <li class="">
            <a routerLink='/Home'>Home Page</a>
          </li>
          <li class="">
            <a routerLink='/aboutus'>About Us</a>
          </li>
          <li class="">
            <a routerLink='/Services'>Services</a>
          </li>
          <li class="">
            <a routerLink='/Buisness'>Our Gallery</a>
          </li>
          <li class="">
            <a routerLink='/FAQ'>Common Questions </a>
          </li>
          <li class="">
            <a routerLink='/Contact'>Contact Us</a>
          </li>
         
        </ul>
      </div>


      <div class="col-sm-3 mb-5">
        <ul class="list-unstyled">
          Locations
          <hr />
          <li class="" *ngFor="let section of ContactSectionList">
            <a routerLink='/Contact'>{{section.location}}</a>
          </li>
  
         
    
        </ul>
      </div>

   

      <div class="col-sm-3 mb-5">
        <ul class="list-unstyled">
          Services
          <hr />
          <li class="" *ngFor="let section of ServicesSectionList">
            <a routerLink='/Services'>{{section.ourServicesTitle}}</a>
          </li>
          
        </ul>
      </div>

      <div class="col-sm-2 mb-5">
        <ul class="list-unstyled" *ngFor="let section of ContactSectionList">
          Contact
          <hr />
          <li class="">
            <a routerLink='/Contact'>{{section.phoneNumber}}
            </a>
          </li>
          <li class="">
            <a style="font-size: 11px" href="mailto:{{section.email}}?subject=Complete Energy">{{section.email}}</a>
          </li>
          <li class="social row d-flex justify-content-around mt-3">
            <a href="{{section.linkedInLink}}" class="col-sm-4"><i class="fab fa-linkedin"></i></a>                  
            <a href="{{section.facebookLink}}" class="col-sm-4"><i class="fab fa-facebook-f"></i></a>                  
            <a href="{{section.instagramLink}}" class="col-sm-4"><i class="fab fa-instagram"></i></a>                  
          </li>
        </ul>
      </div>

    </div>
  </div>
  <div class="copyright text-center">© 2021. complete energy</div>

</footer>
      <!-- End Footer Section -->