      <!-- Header Section -->
      <header style="height: auto;position: inherit;" >
        <div class="container">
          <!-- icons -->
          <nav class="navbar no-wrap " dir="rtl">
            <a routerLink='/Home-AR' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
              " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
            <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              

              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
            <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">

            <ul class="nav-menu  " style="font-size: larger;">
              <li class="nav-item">
                <a routerLink='/Home-AR' class="nav-link   active">الصفحه الرئيسيه</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus-AR' class="nav-link " >من نحن</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services-AR' class="nav-link ">خدماتنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness-AR' class="nav-link ">نجاحنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact-AR' class="nav-link ">تواصل معنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ-AR'class="nav-link ">الأساله الشائعه</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="display: inline-flex;"
    
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item"  (click)="English()">English</a>
                  <a class="dropdown-item"  (click)="Arabic()" >عربي</a>
                </div>
              </li>
            </ul>
           
            </div>
          </nav>
          <!-- content -->
          <div class="row" style="justify-content: center;">
            <div class="language col-sm-2 text-center"  data-aos="fade-left" data-aos-duration="2000" style="margin-top: 0;     margin-left: -130px;
            ">
              <span class="lang mb-5"></span>
              <div class="buttons mt-3 mb-4">
                <a class="english btn btn-light" (click)="English()">English</a>
                <a class="arabic btn btn-light" (click)="Arabic()">عربي</a>
              </div>
              <span class="lang mt-5 mb-5"></span>
            </div>
            <div class="content  text-center" style="width: 60%;" data-aos="zoom-in" data-aos-duration="2000" *ngFor="let section of homeSectionList">
              <h2 class="title-section">{{section.headerTitle}}</h2>
              <p class="text mt-5" 
>
                {{section.headerParagraph}}
              </p>
              <span class="button mb-5"
                ><a routerLink='/Contact-AR'><button class="btn btn-light">تواصل معنا</button></a></span
              >
            </div>

           
          </div>
        </div>
      </header>
      <!-- End Header Section -->

      <!-- Services Section -->
      <section class="services" style="margin-top: 100px;" >
        <div class="container">
          <div class="row" *ngFor="let section of homeSectionList">
           
            <div class="services-image col-sm-6"  data-aos="fade-up" data-aos-duration="2000">
              <img class="img-fluid" style="margin-bottom: 15px;"
               [src]="createImgPath(section.sectionOneServicesImageOneLeft)" />
            </div>
            <div class="col-sm-1"></div>
            <div class="services-content col-sm-5"  data-aos="fade-right" data-aos-duration="2000" >
              <h2 class="title" style="text-align: right; margin-bottom: 15px;">{{section.sectionOneServicesTitle}}</h2>
              <p class="text" style="margin-top: 10px; text-align: right;  
              ">
                {{section.sectionOneServicesParagraph}}                
              </p>
              <!-- <p class="text">
                Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been
                the industry's standard.
              </p> -->
              <span class="button mb-5"><a routerLink='/Services-AR' class="btn btn-light">معرفه المزيد</a></span>
            </div>
          </div>
        </div>
      </section>
      <!-- End Services Section -->

      <!-- Contact Section -->
      <section class="contact">
        <div class="container">
          <div class="row all" *ngFor="let section of homeSectionList">
            
            <div class="contact-content col-sm-3 mb-5 mt-5 text-center"  data-aos="flip-left" data-aos-duration="2000">
              <div class="mb-5">
                <svg
                  id="clock"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 60.506 60.506"
                >
                  <g id="Group_1" data-name="Group 1">
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M30.253,0A30.253,30.253,0,1,0,60.506,30.253,30.253,30.253,0,0,0,30.253,0ZM43.332,36.007H30.506c-.043,0-.084-.01-.127-.012s-.083.012-.127.012a2.092,2.092,0,0,1-2.092-2.092V12.555a2.092,2.092,0,1,1,4.185,0V31.822H43.331a2.092,2.092,0,0,1,0,4.185Z"
                      fill="#172749"
                    />
                  </g>
                </svg>
              </div>
              <p class="black-text">{{section.sectionTwoWorksHour}}</p>
              <p class="gray-text">ساعات العمل</p>
            </div>            

            <div class="contact-content col-sm-3 mb-5 mt-5 text-center"  data-aos="flip-up" data-aos-duration="2000">
              <div class="mb-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 62 62"
                >
                  <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="31"
                    cy="31"
                    r="31"
                    fill="#172749"
                  />
                  <g
                    id="_01-home"
                    data-name="01-home"
                    transform="translate(17.981 13.764)"
                  >
                    <g id="glyph" transform="translate(0 0)">
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M73.274,3.888a13.252,13.252,0,0,0-13.25,13.25c0,3.554,1.989,8.583,6.235,14.029a70.909,70.909,0,0,0,6.235,7.015,1.461,1.461,0,0,0,1.559,0,70.911,70.911,0,0,0,6.235-7.015c4.247-5.447,6.235-10.475,6.235-14.029A13.252,13.252,0,0,0,73.274,3.888Zm0,22.6a9.353,9.353,0,1,1,9.353-9.353A9.276,9.276,0,0,1,73.274,26.491Z"
                        transform="translate(-60.024 -3.888)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p class="black-text">{{section.sectionTwoGetDirection}}</p>
              <p class="gray-text">العنوان</p>
            </div>            

            <div class="contact-content col-sm-3 mb-5 mt-5 text-center"  data-aos="flip-right" data-aos-duration="2000">
              <div class="mb-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 62 62"
                >
                  <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="31"
                    cy="31"
                    r="31"
                    fill="#172749"
                  />
                  <g id="phone-call" transform="translate(14 13.77)">
                    <g
                      id="Group_3"
                      data-name="Group 3"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M34.138,25.376,29.313,20.55a3.209,3.209,0,0,0-5.342,1.206,3.284,3.284,0,0,1-3.791,2.068c-3.447-.862-8.1-5.342-8.961-8.961a3.123,3.123,0,0,1,2.068-3.791A3.209,3.209,0,0,0,14.493,5.73L9.667.9A3.442,3.442,0,0,0,5.014.9L1.74,4.179c-3.274,3.447.345,12.58,8.444,20.68S27.417,36.75,30.864,33.3l3.274-3.274A3.442,3.442,0,0,0,34.138,25.376Z"
                        transform="translate(-0.539 0)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p class="black-text">{{section.sectionTwoCallUs}}</p>
              <p class="gray-text">اتصل بنا</p>
            </div>
          </div>
        </div>
      </section>
      <!-- End Contact Section -->

      <!-- About us Section -->
      <section class="about-us" style="margin-top: 100px;">
        <div class="container">
          <div style="direction: rtl" class="row"  *ngFor="let section of homeSectionList">
          
            <div class="about-us-image col-sm-6"  data-aos="fade-up" data-aos-duration="2000">
              <img
                class="img-fluid"
                [src]="createImgPath(section.sectionThreeAboutUsImage)"
                width="550px"
                style="margin-bottom: 15px;"

              />
            </div>
            <div class="col-sm-1"></div>

            <div class="about-us-content col-sm-5"  data-aos="fade-left" data-aos-duration="2000">
              <h2 class="title" style="text-align: right; margin-bottom: 15px;">{{section.sectionThreeAboutUsTitle}}</h2>
              <p class="text"  style="margin-top: 10px; text-align: right;  ">
                {{section.sectionThreeAboutUsParagraph}}
              </p>
              <!-- <p class="text">
                Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been
                the industry's standard.
              </p> -->
              <span class="button mb-5"><a routerLink='/aboutus-AR' class="btn btn-light">معرفه المزيد</a></span>
            </div>



          </div>
        </div>
      </section>
      <!-- End About us Section -->
      