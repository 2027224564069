import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { services } from 'src/app/Models/services';
import { ServicesSevtionService } from 'src/app/Services/services-sevtion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-service-arabic',
  templateUrl: './our-service-arabic.component.html',
  styleUrls: ['./our-service-arabic.component.css']
})
export class OurServiceArabicComponent implements OnInit  {


  constructor(private Servicesservices:ServicesSevtionService , private router:Router) {

   }
  
  serviceSections:services[]=[];
  ServicesSectionList:services[]=[];

  CurrentServiceSection =null;
  ngOnInit(): void {
     this.GetAllServicesSection();
  }
  GetAllServicesSection()
  {
    this.Servicesservices.getServicesSections().subscribe((data:any)=>{
      this.serviceSections = data;
 
      this.serviceSections.forEach(section => {
        if(section.isArabic == true){

          this.ServicesSectionList.push(section);
        }
      });
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error-AR'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
  addServicessections(){
    this.router.navigate(['/addServicesection']);

  }
  deleteServiceSections(id) {
    this.Servicesservices.deleteServicesSections(id).subscribe();
    alert("the sections deleted");
  }
  
  updateServiceSections(id): void {
    this.router.navigate(['/updateServiceSections',id]);
      // this.aboutservices.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }
    
 public createImgPath = (serverPath: string) => {
  return `https://api.complete-energy.co/${serverPath}`;
}
public response: {dbPath: ''};
onCreate()
{
  this.CurrentServiceSection = {
    ourServicesImage: this.response.dbPath


  }
}
public uploadFinished = (event) => {
  this.response = event;
}
English(){
  sessionStorage.setItem('lang','false');

  this.router.navigate(['/Services'])
.then(() => {
  window.location.reload();
});

}
Arabic(){
  sessionStorage.setItem('lang','true');
  

  this.router.navigate(['/Services-AR'])
.then(() => {
  window.location.reload();
});
 

}
}
