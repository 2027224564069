import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { services } from '../Models/services';

@Injectable({
  providedIn: 'root'
})
export class ServicesSevtionService {

  constructor(private http: HttpClient) { }
  url_service=`https://api.complete-energy.co/api/Services`;

  ngOnInit() {          
  }
  addServicesSctions(services : services)
  {
    return this.http.post(this.url_service,services);
  } 
  getServicesSections(){
    return this.http.get(this.url_service);
  }
  deleteServicesSections(id:any): Observable<any> {
    return this.http.delete(`${this.url_service}/${id}`);
  }
  updateServicesSections(id:any,service:any): Observable<any> {
    return this.http.put(`${this.url_service}/${id}`,service);
  }
  getServicesSectionByID(id:any): Observable<any> {
    return this.http.get(`${this.url_service}/${id}`);
  }
  
}
