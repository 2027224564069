 <!-- Footer Section -->
 <footer style="margin-top: 150px; margin-bottom: 0;">
    <div class="container">
      <div class="foot row"  style="padding-top: 100px; color: #fff; text-align: initial; direction: rtl;">
        <div class="logo col-sm-2 mb-5">
          <a href="index.html"><img class="img-fluid" src="../../../assets/images/Logo/Lockup_white.png?v=1.3"></a>
        </div>
  
        <div class="col-sm-2 mb-5">
          <ul class="list-unstyled" >
            روابط سريعه
            <hr />
            <li class="">
              <a routerLink='/Home-AR'>الصفحه الرئيسيه</a>
            </li>
            <li class="">
              <a routerLink='/aboutus-AR'>من نحن</a>
            </li>
            <li class="">
              <a routerLink='/Services-AR'>خدماتنا</a>
            </li>
          
            <li class="">
              <a routerLink='/Buisness-AR'>نجاحنا</a>
            </li>
            <li class="">
              <a routerLink='/FAQ-AR'>الاساله الشائعه</a>
            </li>
            <li class="">
              <a routerLink='/Contact-AR'>تواصل معنا</a>
            </li>
          </ul>
        </div>
  
        <div class="col-sm-3 mb-5">
          <ul class="list-unstyled">
            مواقعنا
            <hr />
            <li class=""  *ngFor="let section of ContactSectionList">
              <a routerLink='/Contact-AR'> {{section.location}} </a>
            </li>
   
          </ul>
        </div>
  

  
        <div class="col-sm-3 mb-5">
          <ul class="list-unstyled">
            الخدمات
            <hr />
            <li class="" *ngFor="let section of ServicesSectionList">
              <a routerLink='/Services-AR'>{{section.ourServicesTitle}}</a>
            </li>
          </ul>
        </div>
  
        <div class="col-sm-2 mb-5">
          <ul class="list-unstyled"  *ngFor="let section of ContactSectionList">
            تواصل معنا
            <hr />
            <li class="">
              <a routerLink='/Contact-AR'>{{section.phoneNumber}}
              </a>
            </li>
            <li class="">
              <a style="font-size: 11px" href="mailto:{{section.email}}.com?subject=Complete Energy">{{section.email}}</a>
            </li>
            <li class="social row d-flex justify-content-around mt-3">
              <a href="{{section.linkedInLink}}" class="col-sm-4"><i class="fab fa-linkedin"></i></a>                  
              <a href="{{section.facebookLink}}" class="col-sm-4"><i class="fab fa-facebook-f"></i></a>                  
              <a href="{{section.instagramLink}}" class="col-sm-4"><i class="fab fa-instagram"></i></a>                  
            </li>
          </ul>
        </div>
  
      </div>
    </div>
    <div class="copyright text-center">© 2021. complete energy</div>
  
  </footer>
        <!-- End Footer Section -->