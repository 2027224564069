import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { buisness } from 'src/app/Models/buisness';
import { images } from 'src/app/Models/images';
import { ImagesService } from 'src/app/Services/images.service';

@Component({
  selector: 'app-buisness-images',
  templateUrl: './buisness-images.component.html',
  styleUrls: ['./buisness-images.component.css']
})
export class BuisnessImagesComponent implements OnInit  {


  constructor(private ImageService:ImagesService , private router:Router) {

   }
  
  ImagesSections:images[]=[];
  ImageSectionList:images[]=[];

  CurrentImagesSection =null;
  ngOnInit(): void {
     this.GetAllImagessSection();
  }
  GetAllImagessSection()
  {
    this.ImageService.getImagesSections().subscribe((data:any)=>{
      this.ImagesSections = data;
 
      this.ImagesSections.forEach(section => {
        
          this.ImageSectionList.push(section);
      });
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
  addImagessections(){
    this.router.navigate(['/addImagesection']);

  }
  deleteImagesSections(id) {
    this.ImageService.deleteImagesSections(id).subscribe();
    alert("the sections deleted");
  }
  

}
