import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { buisness } from 'src/app/Models/buisness';
import { images } from 'src/app/Models/images';
import { ImagesService } from 'src/app/Services/images.service';
import { BuisnessService } from 'src/app/Services/buisness.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-buiness-arabic',
  templateUrl: './buiness-arabic.component.html',
  styleUrls: ['./buiness-arabic.component.css']
})
export class BuinessArabicComponent implements OnInit  {


  constructor(private _lightbox: Lightbox,private BuisnessService:BuisnessService,private ImageService:ImagesService ,private sanitizer: DomSanitizer , private router:Router) {

   }

   skip:Boolean = false

  BuisnessSections:buisness[]=[];
  BuisnessSectionList:buisness[]=[];

  CurrentBuisnessSection =null;
  content:string;
  album:any=[]
  link:string;
    ImagesSections:images[]=[];
  ImageSectionList:images[]=[];
   num:string
  CurrentImagesSection =null;
  ngOnInit(): void {
     this.GetAllBuisnesssSection();
          this.GetAllImagessSection();
          this.album=[];

  }
  GetAllImagessSection()
  {
    this.skip=true;

    this.ImageService.getImagesSections().subscribe((data:any)=>{
      this.ImagesSections = data;
 
      this.ImagesSections.forEach((section:any) => {
        
          this.ImageSectionList.push(section);
          this.album.push({'src': `${this.createImgPath(section.businessImage)}`,'caption':'','thumb': `${this.createImgPath(section.businessImage)}`});

     this.num=this.ImageSectionList.length.toString();
    localStorage.setItem('imagenumber', this.num);
    
    
      });
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error-AR'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
  open(index: number): void {

    this._lightbox.open(this.album, index);
    }
    
    close(): void {
    
    this._lightbox.close();
    }
  GetAllBuisnesssSection()
  {
    this.skip=false;
    this.BuisnessService.getBuisnessSections().subscribe((data:any)=>{
      this.BuisnessSections = data;
 
      this.BuisnessSections.forEach((section:any) => {
        if(section.isArabic == true){

        this.link= section.businessLinkVedio
          this.BuisnessSectionList.push(section);
          this.content= this.getHTML(this.link);
        }
      });

    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error-AR'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });


  }
 
  getHTML(l){
    // https://www.youtube.com/embed/A3PDXmYoF5U
    //https://www.youtube.com/watch?v=x6ddHw3GBrc

     var url = l;
  url = url.split('v=')[1];
  l= "https://www.youtube.com/embed/" + url + "?autoplay=1";
 


    let text:any = `<iframe width="1000" height="733" src="${l}" frameborder="0" allowfullscreen></iframe>`
    text = this.sanitizer.bypassSecurityTrustHtml(text);
          return text;
  }
//   imgCollection: Array<object> = [
//     {
//       image: '../../../assets/images/1.jpg',
//       thumbImage: '../../../assets/images/1.jpg',
//       alt: 'Image 1',
//       title: 'Image 1'
//     }, { image: '../../../assets/images/10.jpg',
//     thumbImage: '../../../assets/images/10.jpg',
//       title: 'Image 2',
//       alt: 'Image 2'
//     }, { image: '../../../assets/images/11.jpg',
//     thumbImage: '../../../assets/images/11.jpg',
//       title: 'Image 3',
//       alt: 'Image 3'
//     }, { image: '../../../assets/images/12.jpg',
//     thumbImage: '../../../assets/images/12.jpg',
//       title: 'Image 4',
//       alt: 'Image 4'
//     }, { image: '../../../assets/images/13.jpg',
//     thumbImage: '../../../assets/images/13.jpg',
//       title: 'Image 5',
//       alt: 'Image 5'
//     }, { image: '../../../assets/images/1.jpg',
//     thumbImage: '../../../assets/images/1.jpg',
//       title: 'Image 6',
//       alt: 'Image 6'
//     }
// ];

public createImgPath = (serverPath: string) => {
  return `https://api.complete-energy.co/${serverPath}`;
}



public response: {dbPath: ''};
onCreate()
{
  this.CurrentBuisnessSection = {
    BusinessImageCoverVideo : this.response.dbPath

  }
   this.CurrentImagesSection={
  BusinessImage:this.response.dbPath

    }
}
public uploadFinished = (event) => {
  this.response = event;
}



English(){
  sessionStorage.setItem('lang','false');

  this.router.navigate(['/Buisness'])
.then(() => {
  window.location.reload();
});

}
Arabic(){
  sessionStorage.setItem('lang','true');
  

  this.router.navigate(['/Buisness-AR'])
.then(() => {
  window.location.reload();
});

this.album=[];


}
}

