import { Injector } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
 
  constructor(private injector: Injector) {
  }
  handleError(error) {
 
    let router = this.injector.get(Router);
   
    router.navigate(['/error']);
  }
  
}