       <!-- Header Section -->
       <header style="height: 400px; position:inherit">
        <div class="container">
          <!-- icons -->
          <nav class="navbar no-wrap " dir="rtl">
            <a routerLink='/Home-AR' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
              " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
            <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              
    
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
            <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">
    
            <ul class="nav-menu  " style="font-size: larger; margin-right: 15%;">
              <li class="nav-item">
                <a routerLink='/Home-AR' class="nav-link  ">الصفحه الرئيسيه</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus-AR' class="nav-link " >من نحن</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services-AR' class="nav-link active ">خدماتنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness-AR' class="nav-link ">نجاحنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact-AR' class="nav-link ">تواصل معنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ-AR'class="nav-link ">الأساله الشائعه</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="display: inline-flex;"
    
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item"  (click)="English()">English</a>
                  <a class="dropdown-item"  (click)="Arabic()" >عربي</a>
                </div>
              </li>
            </ul>
           
            </div>
          </nav>
          <!-- content -->
               <!-- content -->
               <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000" >
                <h2 class="title" style="font-size: 2em;">خدماتنا</h2>
              </div>
        </div>
      </header>
      <!-- End Header Section -->
        <!-- Start First Section -->
          <section class="first" style="margin-top: 0;" >
            <div class="container" >
              <ng-template ngFor let-item [ngForOf]="ServicesSectionList" let-i="index" >
    
              <div *ngIf="i%2 == 0"  class="row left-section" style="padding-bottom: 70px; padding-top: 70px;" >
               
                <div class="first-content col-sm-6" data-aos="fade-up" data-aos-duration="2000" >
                  <h2 class="title" style="text-align: right;">{{item.ourServicesTitle}}</h2>
                  <p class="text" style=" text-align: right;">
                   {{item.ourServicesParagraph}}
                  </p>
                 
                </div>
    
                <div class="first-image col-sm-6" data-aos="fade-left" data-aos-duration="2000"   >
                  <img class="img-fluid" [src]="createImgPath(item.ourServicesImage)" />
                </div>
              </div>
              <div *ngIf="!(i%2 == 0)"  style="direction: rtl" class="row right-section" >
                <div class="second-content col-sm-6" data-aos="fade-up" data-aos-duration="2000">
                  <h2 class="title" style="text-align: right;">{{item.ourServicesTitle}}</h2>
                  <p class="text" style="text-align: right;">
                    {{item.ourServicesParagraph}}
    
                  </p>
               
                </div>
    
                <div class="second-image col-sm-6" data-aos="fade-right" data-aos-duration="2000">
                  <img class="img-fluid" [src]="createImgPath(item.ourServicesImage)" />
                </div>
              </div>
            </ng-template>
    
            </div>
          </section>
          <!-- End First Section -->
    
          
    
       