import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { aboutus } from 'src/app/Models/aboutus';
import { AboutusService } from 'src/app/Services/aboutus.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit  {


  constructor(private aboutservices:AboutusService , private router:Router) {

   }
  
  aboutSections:aboutus[]=[];
  aboutSectionList:aboutus[]=[];
  checkList:aboutus[]=[]

  id:number;
  CurrentAboutSection =null;
  ngOnInit(): void {
     this.GetAllAboutSection();
  }
  GetAllAboutSection()
  {
    this.aboutservices.getAboutSections().subscribe((data:any)=>{
      this.aboutSections = data;
      this.checkList=data;
      this.id=data[0].id;
      this.aboutSections.forEach(section => {
        if(section.isArabic == false){

          this.aboutSectionList.push(section);
        }
      });
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
  addAboutsection(){
    this.router.navigate(['/addaboutsection']);

  }
  deleteAboutSections() {
    this.aboutservices.deleteAboutSections(this.id).subscribe();
    alert("the sections deleted");
  }
  
  updateAboutSections(): void {
    this.router.navigate(['/updateaboutsection',this.id]);
      // this.aboutservices.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }
    

 public createImgPath = (serverPath: string) => {
  return `https://api.complete-energy.co/${serverPath}`;
}
public response: {dbPath: ''};
onCreate()
{
  this.CurrentAboutSection = {
    ourVisionImage: this.response.dbPath,
    ourMisionImage : this.response.dbPath

  }
}
public uploadFinished = (event) => {
  this.response = event;
}
English(){
  sessionStorage.setItem('lang','false');

  this.router.navigate(['/aboutus'])
.then(() => {
  window.location.reload();
});

}
Arabic(){
  sessionStorage.setItem('lang','true');
  

  this.router.navigate(['/aboutus-AR'])
.then(() => {
  window.location.reload();
});
 

}
}
