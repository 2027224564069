       <!-- Header Section -->
       <header style="height: 400px;position: inherit; ">
        <div class="container">
          <!-- icons -->
          <nav class="navbar no-wrap " dir="rtl">
            <a routerLink='/Home-AR' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
              " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
            <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              
    
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
            <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">
    
            <ul class="nav-menu  " style="font-size: larger; margin-right: 15%;">
              <li class="nav-item">
                <a routerLink='/Home-AR' class="nav-link  ">الصفحه الرئيسيه</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus-AR' class="nav-link " >من نحن</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services-AR' class="nav-link ">خدماتنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness-AR' class="nav-link  ">نجاحنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact-AR' class="nav-link active ">تواصل معنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ-AR'class="nav-link ">الأساله الشائعه</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="display: inline-flex;"
    
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item"  (click)="English()">English</a>
                  <a class="dropdown-item"  (click)="Arabic()" >عربي</a>
                </div>
              </li>
            </ul>
           
            </div>
          </nav>
          <!-- content -->
               <!-- content -->
               <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000" >
                <h2 class="title" style="font-size: 2em;">تواصل معنا</h2>
              </div>
        </div>
      </header>
      <!-- End Header Section -->
      <div style="clear: both;"></div>

 <!-- Start Contact Us Section -->
 <section class="contact-us" data-aos="fade-up" data-aos-duration="2000" dir="rtl" >
  <div class="container" style="max-width: 1140px;">
      <div class="row box-shad">

        <div class="left-side col-sm-5" *ngFor="let section of ContactSectionList">
            <h3 style="    margin-bottom: .5rem;
            font-weight: 500;
            line-height: 1.2;
            color: #fff; color:#FFF; border:none">تواصل معنا</h3>
            <div class="info">
                <ul class="list-unstyled" style="text-align: right;">
                    <li>  
                        <span class="icons"> <i class="fas fa-map-marker-alt"></i> </span>                              
                        <span class="content">{{section.location}}</span>
                    </li>
                    <li>
                        <span class="icons"><i class="fas fa-phone-alt"></i></span>                                                      
                        <span class="content">{{section.phoneNumber}}</span>
                    </li>
                    <li>
                        <span class="icons"><i class="fas fa-envelope"></i></span>                                                                                                                                      
                        <span class="content"><a href="mailto:{{section.email}}.com?subject=Complete Energy" style="color: #fff;">{{section.email}}</a></span>
                    </li>
                    <li>
                        <span class="icons"><i class="fas fa-clock"></i></span>                                            
                        <span class="content">{{section.availableTime}}</span>
                    </li>
                </ul>
            </div>
            <hr>
            <div class="social-media mb-3" style="text-align: right;">
                <a href="{{section.facebookLink}}"> <i class="fab fa-facebook-f"> </i></a>
                <a href="{{section.instagramLink}}"><i class="fab fa-instagram"></i></a>
                <a href="{{section.linkedInLink}}"><i class="fab fa-linkedin-in"></i></a>
            </div>
           <div class="map">
            <div [innerHTML]="content"></div>

            
            </div>
        </div>

         <div class="right-side col-sm-7">
             <h3 style="    margin-bottom: .5rem;
             font-size:1.75rem;
             font-weight: 500;
             line-height: 1.2; border:none">ارسال رساله</h3>
             <form>
                 <div class="row">
                     <input type="text" class="data col-sm-5 mb-5" placeholder=" الاسم الاول" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                     <span class="col-sm-1"></span>
                     <input type="text" class="col-sm-5 mb-5" placeholder="الاسم الاخير">
                 </div>
                 <div class="row">
                    <input type="email" class="data col-sm-5 mb-5" placeholder="الايميل" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                    <span class="col-sm-1"></span>
                    <input type="tel" class="col-sm-5 mb-5" placeholder="رقم الهاتف">
                </div>
                <div class="row">
                    <input type="text" class="data col-sm-11 mb-5" placeholder=" الموضوع" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                </div>
                <div class="row">                            
                    <input type="text" class="data text col-sm-11 mb-5" placeholder=" الرساله" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                </div>
                <div class="text-center mt-5">
                    <button class="btn btn-info mt-5">ارسال</button>
                </div>
             </form>
         </div>
      </div>
  </div>
</section>
<!-- End Contact Us Section -->
<div style="clear: both;"></div>
