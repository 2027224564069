import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page-arabic',
  templateUrl: './error-page-arabic.component.html',
  styleUrls: ['./error-page-arabic.component.css']
})
export class ErrorPageArabicComponent implements OnInit{

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  English(){
    sessionStorage.setItem('lang','false');
  
    this.router.navigate(['/error'])
  .then(() => {
    window.location.reload();
  });
  
  }
  Arabic(){
    sessionStorage.setItem('lang','true');
    
  
    this.router.navigate(['/error-AR'])
  .then(() => {
    window.location.reload();
  });
   
  
  }
}
