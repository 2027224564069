          <!-- Header Section -->
          <header style="height: 400px; position: inherit;">
            <div class="container">
              <!-- icons -->
              <nav class="navbar no-wrap ">
                <a routerLink='/Home' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
                  " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
                <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  
        
                  <span class="bar"></span>
                  <span class="bar"></span>
                  <span class="bar"></span>
                </div>
                <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">
        
                <ul class="nav-menu  " style="font-size: larger;">
                  <li class="nav-item">
                    <a routerLink='/Home' class="nav-link ">Home</a>
                  </li>
                  <li class="nav-item">
                    <a routerLink='/aboutus' class="nav-link " >About us</a>
                  </li>
                  <li class="nav-item">
                    <a routerLink='/Services' class="nav-link ">Our services</a>
                  </li>
                  <li class="nav-item">
                    <a routerLink='/Buisness' class="nav-link ">Our Gallery</a>
                  </li>
                  <li class="nav-item">
                    <a routerLink='/Contact' class="nav-link ">Contact Us</a>
                  </li>
                  <li class="nav-item">
                    <a routerLink='/FAQ'class="nav-link active ">Common questions</a>
                  </li>
                  <li id="language" class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="display: inline-flex;"
        
                    >
                      Language
                    </a>
                    <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item"  (click)="English()">English</a>
                      <a class="dropdown-item" (click)="Arabic()" >عربي</a>
                    </div>
                  </li>
                </ul>
               
                </div>
              </nav>
              <!-- content -->
                   <!-- content -->
                   <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000" >
                    <h2 class="title" style="font-size: 2em;">COMMON QUESTIONS</h2>
                  </div>
            </div>
          </header>
          <!-- End Header Section -->
   <!-- FAQ section starts  -->
      <section id="faq" class="faq" >
        <div class="row">
            <div class="container" >
               
              <h2 class="faq-title text-center" data-aos="flip-up" data-aos-duration="2000" style="font-size: 2em;">Common questions</h2>
              <div *ngFor="let section of FAQSectionList;let i=index">   

              <div class="accordion-container"  >    
            
                    <div class="accordion" data-aos="fade-left"  >

                        <div  class="accordion-header d-flex justify-content-between">                            
                            <h3 style="padding-bottom: 0; margin-bottom: 0; color: #4a4a4a
                            ;">
                                {{section.question}}</h3>
                                <a class="btn" (click)=toggleDisplayDiv(i) > 
                                  <span><i class="fas fa-chevron-down" style="color: #4a4a4a
                                    ;"></i></span></a>
                        </div>
                        <div *ngIf="indx==i" class="accordion-body" [hidden]="isShowDiv">
                            <p style="    display: -webkit-box;
                            ">{{section.answer}}</p>
                        </div>
                    </div>
                  </div> 
                </div>
            </div>
        </div>
    </section>
    <!-- FAQ section ends -->

 