import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { homepage } from 'src/app/models/homepage';
import { HomeService } from 'src/app/Services/home.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-arabic',
  templateUrl: './home-arabic.component.html',
  styleUrls: ['./home-arabic.component.css']
})
export class HomeArabicComponent  implements OnInit {


  constructor(private homeservices:HomeService , private router:Router) {

   }
  
  homeSections:homepage[]=[];
  homeSectionList:homepage[]=[];

  CurrentHomrSection =null;
  ngOnInit(): void {
     this.GetAllHomeSection();
     sessionStorage.setItem('lang','true');
     this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }
  GetAllHomeSection()
  {
    this.homeservices.getHomeSections().subscribe((data:any)=>{
      this.homeSections = data;
      this.homeSections.forEach(section => {
        if(section.isArabic == true){

          this.homeSectionList.push(section);
        }
      });
      
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error-AR'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
  public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  onCreate()
  {
    this.CurrentHomrSection = {
      sectionOneServicesImageOneLeft: this.response.dbPath,
      sectionOneServicesImageTwoRight : this.response.dbPath,
      sectionOneServicesImageThreeRight :this.response.dbPath,
      sectionThreeAboutUsImage :this.response.dbPath

    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }
  English(){
    sessionStorage.setItem('lang','false');

    this.router.navigate(['/Home'])
    .then(() => {
      window.location.reload();
    });

  }
  Arabic(){
    sessionStorage.setItem('lang','true');
    
    this.router.navigate(['/Home-AR'])
    .then(() => {
      window.location.reload();
    });

  }
}
