       <!-- Header Section -->
       <header style="height: 400px;position: inherit; ">
        <div class="container">
          <!-- icons -->
          <nav class="navbar no-wrap ">
            <a routerLink='/Home' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
              " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
            <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              
    
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
            <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">
    
            <ul class="nav-menu  " style="font-size: larger;">
              <li class="nav-item">
                <a routerLink='/Home' class="nav-link ">Home</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus' class="nav-link " >About us</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services' class="nav-link ">Our services</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness' class="nav-link ">Our Gallery</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact' class="nav-link active">Contact Us</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ'class="nav-link ">Common questions</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="display: inline-flex;"
    
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item"  (click)="English()">English</a>
                  <a class="dropdown-item" (click)="Arabic()" >عربي</a>
                </div>
              </li>
            </ul>
           
            </div>
          </nav>
          <!-- content -->
               <!-- content -->
               <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000" >
                <h2 class="title" style="font-size: 2em;">Error Page</h2>
              </div>
        </div>
      </header>
      <!-- End Header Section -->
      <div style="clear: both;"></div>

      <section style="    text-align: -webkit-center;
      padding-top: 10%;">
   <div style="text-align: center;    text-align: center;
   border: 2px solid steelblue;
   padding: 5%;
   WIDTH: 50%; ">
       <h4 style="font-weight: normal;">Error Acuured Please Try Again</h4>
       <h4 style="font-weight: normal;">Check this:</h4>
       <ul>
           <li><h4 style="font-weight: normal;">Internet Connection</h4></li>
       </ul>
    </div>
      </section>