       <!-- Header Section -->
       <header style="height: 400px;position: inherit; ">
        <div class="container">
          <!-- icons -->
          <nav class="navbar no-wrap ">
            <a routerLink='/Home' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
              " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
            <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              
    
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
            <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">
    
            <ul class="nav-menu  " style="font-size: larger;">
              <li class="nav-item">
                <a routerLink='/Home' class="nav-link ">Home</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus' class="nav-link " >About us</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services' class="nav-link ">Our services</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness' class="nav-link ">Our Gallery</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact' class="nav-link active">Contact Us</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ'class="nav-link ">Common questions</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="display: inline-flex;"
    
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item"  (click)="English()">English</a>
                  <a class="dropdown-item" (click)="Arabic()" >عربي</a>
                </div>
              </li>
            </ul>
           
            </div>
          </nav>
          <!-- content -->
               <!-- content -->
               <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000" >
                <h2 class="title" style="font-size: 2em;">CONTACT US</h2>
              </div>
        </div>
      </header>
      <!-- End Header Section -->
      <div style="clear: both;"></div>

 <!-- Start Contact Us Section -->
 <section class="contact-us" data-aos="fade-up" data-aos-duration="2000" >
  <div class="container" style="max-width: 1140px;">
      <div class="row box-shad">

        <div class="left-side col-sm-5" *ngFor="let section of ContactSectionList">
            <h3 style="    margin-bottom: .5rem;
            font-weight: 500;
            line-height: 1.2;
            color: #fff; color:#FFF; border:none">CONTACT US</h3>
            <div class="info">
                <ul class="list-unstyled"  >
                    <li>  
                        <span class="icons"> <i class="fas fa-map-marker-alt"></i> </span>                              
                        <span class="content">{{section.location}}</span>
                    </li>
                    <li>
                        <span class="icons"><i class="fas fa-phone-alt"></i></span>                                                      
                        <span class="content">{{section.phoneNumber}}</span>
                    </li>
                    <li>
                        <span class="icons"><i class="fas fa-envelope"></i></span>                                                                                                                                      
                        <span class="content"><a style="color: #fff;" href="mailto:{{section.email}}?subject=Complete Energy">{{section.email}}</a></span>
                    </li>
                    <li>
                        <span class="icons"><i class="fas fa-clock"></i></span>                                            
                        <span class="content">{{section.availableTime}}</span>
                    </li>
                </ul>
            </div>
            <hr>
            <div class="social-media mb-3">
                <a href="{{section.facebookLink}}"> <i class="fab fa-facebook-f"> </i></a>
                <a href="{{section.instagramLink}}"><i class="fab fa-instagram"></i></a>
                <a href="{{section.linkedInLink}}"><i class="fab fa-linkedin-in"></i></a>
            </div>
           <div class="map">
            <div [innerHTML]="content"></div>

            
            </div>
        </div>

         <div class="right-side col-sm-7">
             <h3 style="    margin-bottom: .5rem;
             font-size:1.75rem;
             font-weight: 500;
             line-height: 1.2; border:none">Send Message</h3>
             <form>
                 <div class="row">
                     <input type="text" class="data col-sm-5 mb-5" placeholder=" First Name" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                     <span class="col-sm-1"></span>
                     <input type="text" class="col-sm-5 mb-5" placeholder="Last Name">
                 </div>
                 <div class="row">
                    <input type="email" class="data col-sm-5 mb-5" placeholder=" E-mail" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                    <span class="col-sm-1"></span>
                    <input type="tel" class="col-sm-5 mb-5" placeholder="Telephone number">
                </div>
                <div class="row">
                    <input type="text" class="data col-sm-11 mb-5" placeholder=" Subject" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                </div>
                <div class="row">                            
                    <input type="text" class="data text col-sm-11 mb-5" placeholder=" Text of the message" onclick="document.getElementsByClassName('data').style.border = 'none'"> 
                </div>
                <div class="text-center mt-5">
                    <button class="btn btn-info mt-5">Send</button>
                </div>
             </form>
         </div>
      </div>
  </div>
</section>
<!-- End Contact Us Section -->
<div style="clear: both;"></div>
