
      <!-- Header Section -->
      <header style="height: auto;">
        <div class="container">
          <!-- icons -->
          <nav class="navbar row">
            <a routerLink='/Home' class="nav-logo col-sm-2"><img class="img-fluid" src="../../../assets/images/Logo/Lockup_light.png" /></a>

            <ul class="nav-menu" style="font-size: larger;">
              <li class="nav-item">
                <a routerLink='/Home' class="nav-link active">Home</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus' class="nav-link">About us</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services' class="nav-link">Our services</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness' class="nav-link">Business</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact' class="nav-link">Contact Us</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ'class="nav-link">Common questions</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#">English</a>
                  <a class="dropdown-item" href="#">عربي</a>
                </div>
              </li>
            </ul>

            <div class="hamburger col-sm-1">
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
          </nav>
          <!-- content -->
          <div class="row">
            <div class="content col-sm-10 text-center"  data-aos="zoom-in" data-aos-duration="2000">
              <h2 style="font-size: 25px;">Lorem Ipsum is simply dummy</h2>
              <p class="text mt-5" style="color: #fff;">
                text of the printing and typesetting industry. Lorem<br />
                Ipsum has been the industry's standard
              </p>
              <span class="button mb-5"
                ><a href="contact us.html"><button class="btn btn-light">CONTACT US</button></a></span
              >
            </div>

            <div class="language col-sm-2 text-center"  data-aos="fade-left" data-aos-duration="2000">
              <span class="lang mb-5"></span>
              <div class="buttons mt-3 mb-4">
                <a class="english btn btn-light">English</a>
                <a class="arabic btn btn-light">عربي</a>
              </div>
              <span class="lang mt-5 mb-5"></span>
            </div>
          </div>
        </div>
      </header>
      <!-- End Header Section -->