import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { services } from 'src/app/Models/services';

import { ServicesSevtionService } from 'src/app/Services/services-sevtion.service';
import { contactus } from 'src/app/Models/contactus';
import { ContactService } from 'src/app/Services/contact.service';


@Component({
  selector: 'app-footer-ar',
  templateUrl: './footer-ar.component.html',
  styleUrls: ['./footer-ar.component.css']
})
export class FooterArComponent implements OnInit {

 
  constructor(private Servicesservices:ServicesSevtionService , private router:Router , private ContactService:ContactService ) {

  }
   
  ContactSections:contactus[]=[];
  ContactSectionList:contactus[]=[];
  checkList:contactus[]=[];
  content:string;
  link:string;

  id:number;
 serviceSections:services[]=[];
 ServicesSectionList:services[]=[];

 CurrentServiceSection =null;
 ngOnInit(): void {
    this.GetAllServicesSection();
    this.GetAllContactsSection();

 }
 GetAllContactsSection()
 {
   this.ContactService.getContactSections().subscribe((data:any)=>{
     this.ContactSections = data;
     this.checkList=data;
     this.id=data[0].id;
     this.ContactSections.forEach(section => {
       this.link= section.mapIframeklink;
       if(section.isArabic == true){

         this.ContactSectionList.push(section);

       }

     });
   },
   (error) => {                              //Error callback
     console.error('error caught in component')
     
     this.router.navigate(['/error-AR'])
     .then(() => {
       window.location.reload();
     });
     throw error;
   });
 }
 GetAllServicesSection()
 {
   this.Servicesservices.getServicesSections().subscribe((data:any)=>{
     this.serviceSections = data;

     this.serviceSections.forEach(section => {
       if(section.isArabic == true){

         this.ServicesSectionList.push(section);
       }
     });
   },
   (error) => {                              //Error callback
     console.error('error caught in component')
     
     this.router.navigate(['/error-AR'])
     .then(() => {
       window.location.reload();
     });
     throw error;
   });
 }

}
