import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { contactus } from '../Models/contactus';

@Injectable({
  providedIn: 'root'
})
export class ContactService  {

  constructor(private http: HttpClient) { }
  url_contact=`https://api.complete-energy.co/api/ContactUs`;

  ngOnInit() {          
  }
  addContactSctions(Contact : contactus)
  {
    return this.http.post(this.url_contact,Contact);
  } 
  getContactSections(){
    return this.http.get(this.url_contact);
  }
  deleteContactSections(id:any): Observable<any> {
    return this.http.delete(`${this.url_contact}/${id}`);
  }
  updateContactSections(id:any,service:any): Observable<any> {
    return this.http.put(`${this.url_contact}/${id}`,service);
  }
  getContactSectionByID(id:any): Observable<any> {
    return this.http.get(`${this.url_contact}/${id}`);
  }
  
}

