import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OurServiceComponent } from './pages/our-service/our-service.component';
import { BuisnessComponent } from './pages/buisness/buisness.component';
import { BuisnessImagesComponent } from './pages/buisness-images/buisness-images.component';
import { FAQComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeArabicComponent } from './pages/home-arabic/home-arabic.component';
import { AboutUsArabicComponent } from './pages/about-us-arabic/about-us-arabic.component';
import { OurServiceArabicComponent } from './pages/our-service-arabic/our-service-arabic.component';
import { BuinessArabicComponent } from './pages/buiness-arabic/buiness-arabic.component';
import { ContactArabicComponent } from './pages/contact-arabic/contact-arabic.component';
import { FaqArabicComponent } from './pages/faq-arabic/faq-arabic.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorPageArabicComponent } from './pages/error-page-arabic/error-page-arabic.component';

const routes: Routes = [
  {path:'',redirectTo:'Home', pathMatch: 'full' },
  {path:'Home',component:HomeComponent},
  {path:'Home-AR',component:HomeArabicComponent},

  {path:'aboutus',component:AboutUsComponent},
  {path:'aboutus-AR',component:AboutUsArabicComponent},

  {path:'Services',component:OurServiceComponent},
  {path:'Services-AR',component:OurServiceArabicComponent},


  {path:'Buisness',component:BuisnessComponent},
  {path:'Buisness-AR',component:BuinessArabicComponent},

  {path:'Images',component:BuisnessImagesComponent},
  {path:'viewImagesection', component:BuisnessImagesComponent},

  {path:'FAQ', component:FAQComponent},
  {path:'FAQ-AR', component:FaqArabicComponent},


  {path:'Contact', component:ContactComponent},
  {path:'Contact-AR', component:ContactArabicComponent},

  
  {path: 'error', component: ErrorPageComponent },
  {path: 'error-AR', component: ErrorPageArabicComponent }










];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
