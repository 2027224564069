import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { contactus } from 'src/app/Models/contactus';
import { DomSanitizer } from '@angular/platform-browser';

import { ContactService } from 'src/app/Services/contact.service';
@Component({
  selector: 'app-contact-arabic',
  templateUrl: './contact-arabic.component.html',
  styleUrls: ['./contact-arabic.component.css']
})
export class ContactArabicComponent implements OnInit {


  constructor(private ContactService:ContactService ,private sanitizer: DomSanitizer, private router:Router) {

   }
  
  ContactSections:contactus[]=[];
  ContactSectionList:contactus[]=[];
  checkList:contactus[]=[];
  content:string;
  link:string;

  id:number;

  CurrentContactSection =null;
  ngOnInit(): void {
     this.GetAllContactsSection();
  }
  GetAllContactsSection()
  {
    this.ContactService.getContactSections().subscribe((data:any)=>{
      this.ContactSections = data;
      this.checkList=data;
      this.id=data[0].id;
      this.ContactSections.forEach(section => {
        this.link= section.mapIframeklink;
        if(section.isArabic == true){

          this.ContactSectionList.push(section);
          this.content= this.getHTML(this.link);

        }

      });
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error-AR'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
  getHTML(l){
    // https://www.youtube.com/embed/A3PDXmYoF5U
    //https://www.youtube.com/watch?v=x6ddHw3GBrc

     
    let text:any = `<iframe width="400" height="300" src="${l}" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`


    text = this.sanitizer.bypassSecurityTrustHtml(text);
          return text;
  }
  English(){
    sessionStorage.setItem('lang','false');
  
    this.router.navigate(['/Contact'])
  .then(() => {
    window.location.reload();
  });
  
  }
  Arabic(){
    sessionStorage.setItem('lang','true');
    
  
    this.router.navigate(['/Contact-AR'])
  .then(() => {
    window.location.reload();
  });
   
  
  }
}
