import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FAQ } from 'src/app/Models/FAQ';
import { FAQService } from 'src/app/Services/faq.service';
@Component({
  selector: 'app-faq-arabic',
  templateUrl: './faq-arabic.component.html',
  styleUrls: ['./faq-arabic.component.css']
})
export class FaqArabicComponent implements OnInit {


  constructor(private FAQService:FAQService , private router:Router) {

   }
  
  FAQSections:FAQ[]=[];
  FAQSectionList:FAQ[]=[];

  CurrentFAQSection =null;
  ngOnInit(): void {
     this.GetAllFAQsSection();
  }
  GetAllFAQsSection()
  {
    this.FAQService.getFAQSections().subscribe((data:any)=>{
      this.FAQSections = data;
 
      this.FAQSections.forEach(section => {
        if(section.isArabic == true){

          this.FAQSectionList.push(section);
        }
      });
    },
    (error) => {                              //Error callback
      console.error('error caught in component')
      
      this.router.navigate(['/error-AR'])
      .then(() => {
        window.location.reload();
      });
      throw error;
    });
  }
 
   getsectionAnswer(ind)
   {
      return this.FAQSectionList[ind].Answer;
   }
 getsectionQuestion(ind)
   {
     return this.FAQSectionList[ind].Question;
   }

   isShowDiv = false;
   indx
  toggleDisplayDiv(i) {
    this.isShowDiv = !this.isShowDiv;
    this.indx=i;
  }
  English(){
    sessionStorage.setItem('lang','false');
  
    this.router.navigate(['/FAQ'])
  .then(() => {
    window.location.reload();
  });
  
  }
  Arabic(){
    sessionStorage.setItem('lang','true');
    
  
    this.router.navigate(['/FAQ-AR'])
  .then(() => {
    window.location.reload();
  });
   
  
  }
}

