       <!-- Header Section -->
       <header style="height: 400px; position:inherit">
        <div class="container">
          <!-- icons -->
          <nav class="navbar no-wrap " dir="rtl">
            <a routerLink='/Home-AR' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
              " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
            <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              
    
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
            <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">
    
            <ul class="nav-menu  " style="font-size: larger; margin-right: 15%;">
              <li class="nav-item">
                <a routerLink='/Home-AR' class="nav-link  ">الصفحه الرئيسيه</a>
              </li>
              <li class="nav-item">
                <a routerLink='/aboutus-AR' class="nav-link " >من نحن</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Services-AR' class="nav-link ">خدماتنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Buisness-AR' class="nav-link active ">نجاحنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/Contact-AR' class="nav-link ">تواصل معنا</a>
              </li>
              <li class="nav-item">
                <a routerLink='/FAQ-AR'class="nav-link ">الأساله الشائعه</a>
              </li>
              <li id="language" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="display: inline-flex;"
    
                >
                  Language
                </a>
                <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item"  (click)="English()">English</a>
                  <a class="dropdown-item"  (click)="Arabic()" >عربي</a>
                </div>
              </li>
            </ul>
           
            </div>
          </nav>
          <!-- content -->
          <!-- content -->
          <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000">
            <h2 class="title" style="font-size: 2em;">أعمالنا</h2>
          </div>
        </div>
      </header>
      <!-- End Header Section -->
      <!-- Start Business Section -->
      <section class="business mb-5" style="margin-top: 100px; direction: rtl;">
        <div class="container">
          <div class="row" *ngFor="let section of BuisnessSectionList">
            <div class="business-content col-sm-5" data-aos="fade-right" data-aos-duration="2000">
              <h2 class="title" style="text-align: right;">{{section.businessTitle}}</h2>
              <p class="text" style="margin-top: 20px; text-align: right;
              ">
                {{section.businessParagraph}}
              </p>
              <!-- <p class="text">
               Lorem Ipsum is simply dummy text of the printing
               and typesetting industry. Lorem Ipsum has been
               the industry's standard.
             </p> -->
              <span class="button mb-5"><a routerLink='/Contact-AR'><button class="btn btn-light">تواصل معنا الآن 
                    !</button></a></span>
            </div>

            <div class="col-sm-1"></div>
            <div class="business-vedio col-sm-6" data-aos="fade-up" data-aos-duration="2000">

              <div class="vedio row">
                <a>
                  <img class="img-fluid z-depth-1" alt="video" [src]="createImgPath(section.businessImageCoverVideo)">

                  <i (click)="video1.show()" class="play-btn mb-4" style="margin-top: -10%;"></i>
                </a>
              </div>
              <div mdbModal #video1="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                  <!--Content-->
                  <div class="modal-content">

                    <!--Body-->
                    <div class="modal-body mb-0 p-0">
                      <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                       <div [innerHTML]="content"></div>
                      </div>
                    </div>

                  </div>
                  <!--/.Content-->

                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
      <!-- End Business Section -->

      <!-- Start Photos Section -->
      <section class="scroll bg">
        <div class="container">
          <div class="marquee">
            <ul class="marquee-content">
              <li *ngFor="let section of ImageSectionList; let i=index" >
              <img class="img-fluid" [src]="createImgPath(section.businessImage)" (click)="open(i)" >
              </li>
            </ul>
          </div>
        </div>
      </section>
      <!-- End Photos Section -->

      <!-- <section>
        <div class="container">
          <ng-image-slider [images]="imgCollection" #nav></ng-image-slider>
        </div>
      </section> -->
<!-- 
      <section>
        <div class="container">
          <div class="row">



          </div>




        </div>
      </section> -->
