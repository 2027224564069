import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  title = 'HomeSite';
  langset:any
  constructor() {
    this.langset= sessionStorage.getItem('lang') ;
    console.log(this.langset)
  }
  ngOnInit(): void {

  }
 

}
