import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { buisness } from '../Models/buisness';
import { images } from '../Models/images';

@Injectable({
  providedIn: 'root'
})
export class ImagesService  {

  constructor(private http: HttpClient) { }
  url_images=`https://api.complete-energy.co/api/BusinessImages`;

  ngOnInit() {          
  }
  addImagesSctions(Imageses : images)
  {
    return this.http.post(this.url_images,Imageses);
  } 
  getImagesSections(){
    return this.http.get(this.url_images);
  }
  deleteImagesSections(id:any): Observable<any> {
    return this.http.delete(`${this.url_images}/${id}`);
  }
  updateImagesSections(id:any,service:any): Observable<any> {
    return this.http.put(`${this.url_images}/${id}`,service);
  }
  getImagesSectionByID(id:any): Observable<any> {
    return this.http.get(`${this.url_images}/${id}`);
  }
  
}

