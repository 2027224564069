import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { buisness } from '../Models/buisness';
import { FAQ } from '../Models/FAQ';

@Injectable({
  providedIn: 'root'
})
export class FAQService  {

  constructor(private http: HttpClient) { }
  url_faq=`https://api.complete-energy.co/api/FAQs`;

  ngOnInit() {          
  }
  addFAQSctions(faq : FAQ)
  {
    return this.http.post(this.url_faq,faq);
  } 
  getFAQSections(){
    return this.http.get(this.url_faq);
  }
  deleteFAQSections(id:any): Observable<any> {
    return this.http.delete(`${this.url_faq}/${id}`);
  }
  updateFAQSections(id:any,service:any): Observable<any> {
    return this.http.put(`${this.url_faq}/${id}`,service);
  }
  getFAQSectionByID(id:any): Observable<any> {
    return this.http.get(`${this.url_faq}/${id}`);
  }
  
}

