   <!-- Header Section -->
   <header style="height: 400px; position: inherit;">
    <div class="container">
      <!-- icons -->
      <nav class="navbar no-wrap " dir="rtl">
        <a routerLink='/Home-AR' class="nav-logo  "><img  style="    max-height: 10vh;     margin-top: 5px;
          " src="../../../assets/images/Logo/Lockup_color.svg?v=1.3" /></a>
        <div class="hamburger navbar-toggler  " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          

          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
        <div class="collapse navbar-collapse on-desk" id="navbarSupportedContent">

        <ul class="nav-menu  " style="font-size: larger; margin-right: 15%;">
          <li class="nav-item">
            <a routerLink='/Home-AR' class="nav-link  ">الصفحه الرئيسيه</a>
          </li>
          <li class="nav-item">
            <a routerLink='/aboutus-AR' class="nav-link active" >من نحن</a>
          </li>
          <li class="nav-item">
            <a routerLink='/Services-AR' class="nav-link ">خدماتنا</a>
          </li>
          <li class="nav-item">
            <a routerLink='/Buisness-AR' class="nav-link ">نجاحنا</a>
          </li>
          <li class="nav-item">
            <a routerLink='/Contact-AR' class="nav-link ">تواصل معنا</a>
          </li>
          <li class="nav-item">
            <a routerLink='/FAQ-AR'class="nav-link ">الأساله الشائعه</a>
          </li>
          <li id="language" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="display: inline-flex;"

            >
              Language
            </a>
            <div class="lang dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item"  (click)="English()">English</a>
              <a class="dropdown-item"  (click)="Arabic()" >عربي</a>
            </div>
          </li>
        </ul>
       
        </div>
      </nav>
      <!-- content -->
           <!-- content -->
           <div class="content text-center" data-aos="zoom-in" data-aos-duration="2000" >
            <h2 class="title" style="font-size: 2em;">مـن نـكــون </h2>
          </div>
    </div>
  </header>
  <!-- End Header Section -->
    <!-- Start Vision Section -->
     <section class="vision" >
      <div class="container">
        <div style="direction: rtl;" class="row" *ngFor="let section of aboutSectionList">
          <div class="vision-content col-sm-6 text-center"  data-aos="fade-up" data-aos-duration="2000" >
            <h2 class="title">{{section.ourVisionTitle}}</h2>
            <p class="text">
              {{section.ourVisionParagraph}}
            </p>
          </div>

          <div class="vision-image col-sm-6" data-aos="fade-right" data-aos-duration="2000">
            <img class="img-fluid" [src]="createImgPath(section.ourVisionImage)" />
          </div>            
        </div>          
      </div>
    </section>
    <!-- End Vision Section -->

    <!-- Line -->
    <div class="line container">
        <hr>
    </div>

    <!-- Start Mision Section -->
    <section class="mision">
      <div class="container">
        <div class="row" *ngFor="let section of aboutSectionList">
          <div class="mision-content col-sm-6 text-center" data-aos="fade-up" data-aos-duration="2000" >
            <h2 class="title">{{section.ourMisionTitle}}</h2>
            <p class="text">
             {{section.ourMisionParagraph}}
            </p>
          </div>

          <div class="mision-image col-sm-6" data-aos="fade-left" data-aos-duration="2000">
            <img class="img-fluid" [src]="createImgPath(section.ourMisionImage)" />
          </div>            
        </div>          
      </div>
    </section>
    <!-- End Mision Section -->