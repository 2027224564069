import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { footerComponent } from './component/footer/footer.component';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BuisnessComponent } from './pages/buisness/buisness.component';
import { BuisnessImagesComponent } from './pages/buisness-images/buisness-images.component';
import { FAQComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { OurServiceComponent } from './pages/our-service/our-service.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LightboxModule } from 'ngx-lightbox';
import { HomeArabicComponent } from './pages/home-arabic/home-arabic.component';
import { FooterArComponent } from './component/footer-ar/footer-ar.component';
import { AboutUsArabicComponent } from './pages/about-us-arabic/about-us-arabic.component';
import { OurServiceArabicComponent } from './pages/our-service-arabic/our-service-arabic.component';
import { BuinessArabicComponent } from './pages/buiness-arabic/buiness-arabic.component';
import { ContactArabicComponent } from './pages/contact-arabic/contact-arabic.component';
import { FaqArabicComponent } from './pages/faq-arabic/faq-arabic.component';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandlerService } from './Services/GlobalErrorHandlerService';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorPageArabicComponent } from './pages/error-page-arabic/error-page-arabic.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    footerComponent,
    HomeComponent,
    HomeArabicComponent,
    AboutUsComponent,
    OurServiceComponent,
    BuisnessComponent,
    BuisnessImagesComponent,
    FAQComponent,
    ContactComponent,
    FooterArComponent,
    AboutUsArabicComponent,
    OurServiceArabicComponent,
    BuinessArabicComponent,
    ContactArabicComponent,
    FaqArabicComponent,
    ErrorPageComponent,
    ErrorPageArabicComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgImageSliderModule,
    MDBBootstrapModule.forRoot(),
    NoopAnimationsModule,
    LightboxModule 

      
  ],
  providers: [{provide: ErrorHandler, useClass: GlobalErrorHandlerService },],
  bootstrap: [AppComponent]
})
export class AppModule { }
